<template>
  <PageContainer
    :title="entry.title"
    :back="actions.back"
    :has-shy-header="true"
    :ellipsis="true"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div v-if="loading">
          <Skeleton class="skeleton" width="480px" height="176px" borderRadius="8px" />
          <Skeleton class="skeleton" width="480px" height="600px" borderRadius="8px" />
        </div>

        <div v-else class="page-segment-content">
          <!-- entry image cover -->
          <img class="cover" :src="entry.thumbnail" :alt="entry.title">
          <!-- entry content -->
          <div class="body" v-html="entry.html"></div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Skeleton, Blogs } from '@seliaco/red-panda'

export default {
  name: 'Entry',
  components: { PageContainer, Skeleton },
  mounted () {
    this.getEntryDetails()
  },
  data () {
    return {
      entry: {},
      loading: false,
      actions: {
        back: {
          callback: () => this.$router.go(-1)
        }
      }
    }
  },
  methods: {
    getEntryDetails () {
      this.loading = true
      Blogs.getById(this.$route.params.id)
        .then((response) => {
          this.entry = response
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.skeleton
  margin: 12px auto

.cover
  object-position: center
  object-fit: cover
  width: 100%
  height: 176px
  border-radius: 8px

</style>
